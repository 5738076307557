import React from 'react';
import { Link } from 'gatsby';
import useQuest from '../../utils/hooks/swr/useQuest';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '../atoms/inputs/Button';
import SkeletonCommunityCard from '../atoms/quests/SkeletonCommunityCard.js';

export default function CommunityShowcase({ content, index }) {
  const { quest, isLoading, isError } = useQuest(content.questID);

  if (isError) return <div></div>;

  if (isLoading) return <SkeletonCommunityCard index={index} />;

  if (quest)
    return <CommunityCard quest={quest} content={content} index={index} />;
}

function CommunityCard({ quest, content, index }) {
  const videoID = quest.data.relationships.video.data.id;
  const [video] = quest.included.filter(
    (videoResource) => videoID === videoResource.id
  );
  const videoUrl = video.attributes.url;
  const [community] = quest.included.filter(
    (communityResource) => communityResource.type === 'communities'
  );
  const communityName = community.attributes.name;

  const SpacerElement = () => (
    <div className='w-full h-[150px] hidden md:flex justify-center items-center'></div>
  );

  return (
    <div className='flex justify-center items-center flex-col md:block max-w-[350px] w-full'>
      {/* <h4 className="text-center text-2xl mb-5">{communityName}</h4> */}
      {index % 2 != 0 && <SpacerElement />}
      <div
        className='p-1 rounded-3xl bg-secondary-red inline-block w-full md:max-w-[320px] lg:max-w-[350px] min-h-[550px] sm:min-h-[375px] lg:min-h-[550px] mb-8 md:mb-5'>
        <video
          className='rounded-3xl'
          muted
          autoPlay
          playsInline
          loop
          width='100%'
        >
          <source
            src={videoUrl.replace('http://', 'https://')}
            type='video/mp4'
          />
        </video>
      </div>
      <Link to={content.url}>
        <div className='w-full md:max-w-[320px] lg:max-w-[350px] group relative overflow-hidden rounded-3xl'>
          <div
            className='absolute h-full w-full z-10 group-hover:bg-default bg-black bg-opacity-50 group-hover:bg-opacity-40 rounded-3xl transition ease-linear'></div>
          <div
            className='w-full absolute z-20 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-2xl text-white group-hover:text-white'>
            <div className='w-full flex flex-col items-center justify-center p-10 md:p-6 lg:p-10'>
              <h5 className='text-2xl md:text-xl lg:text-2xl mb-3'>
                {communityName}
              </h5>
              <p className='text-sm mb-3 text-center'>
                {content.cta}
              </p>
              <Button
                label={'Discover more'}
                className='text-sm md:text-xs lg:text-sm'
              />
            </div>
          </div>
          <GatsbyImage
            image={getImage(content.image)}
            alt='community card'
            imgStyle={{ borderRadius: '24px' }}
          />
        </div>
      </Link>
      {index % 2 === 0 && <SpacerElement />}
    </div>
  );
}
