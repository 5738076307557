import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayout';
import PrimaryHeader from '../../components/atoms/typography/PrimaryHeader';
import Headline from '../../components/atoms/typography/Headline';
import Spacer from '../../components/atoms/utility/Spacer';
import SEO from '../../components/atoms/utility/seo';
import DownloadAppCTA from '../../components/atoms/callouts/DownloadAppCTA';
import Paragraph from '../../components/atoms/typography/Paragraph';
import CommunityShowcase from '../../components/molecules/CommunityShowcase';

export default function austinCommunityPage({
                                              data: {
                                                austinCommunityHeroImg,
                                                austinCommunityMobileHeroImg,
                                                liveMusicCommunitySquareImg,
                                                beerCommunitySquareImg,
                                                tacoCommunitySquareImg
                                              }
                                            }) {
  const communityShowcaseContent = [
    {
      name: 'Tacos',
      questID: 'f9023569-8add-40c5-b380-2fac87ce9f6d',
      image: tacoCommunitySquareImg,
      url: '/community/tacos',
      cta: 'From food truck to high dining, find your Taco community or next Taco experience in Austin today!'
    },
    {
      name: 'Beer',
      questID: '514d79e9-6c26-4f08-8e9b-a6c7d46ef2aa',
      image: beerCommunitySquareImg,
      url: '/community/beer',
      cta: 'Leave no pint unturned, discover new beer locations in Austin or on the go.'
    },
    {
      name: 'Live Music',
      questID: '6b68bf85-c559-4dea-b80e-6a4da311f77b',
      image: liveMusicCommunitySquareImg,
      url: '/community/live-music',
      cta: 'Stay in the loop on local Live Music events with flockx & experience Austin like never before.'
    }
  ];

  return (
    <BaseLayout
      heroContent={AustinCommunityPageHeroContent}
      heroImg={austinCommunityHeroImg}
      mobileHeroImg={austinCommunityMobileHeroImg}
    >
      <SEO
        title="Dive Into Austin's Live Music, Taco, and Beer Communities"
        keywords={[
          'tacos',
          'taquiero',
          'food',
          'influencer',
          'community',
          'exploration',
          'experience',
          'inspiration',
          'live music',
          'mobile app',
          'flockx'
        ]}
        description={`Explore the vibrant music, food, and beer scenes in Austin with flockx - the app that helps you discover and enjoy the best local experiences in the city. Download now to start planning your next adventure in Austin!`}
      />
      <Spacer sizeY={1} />
      <Spacer className='block md:hidden' />
      <Headline
        spanText={`Explore, Experience & Share`}
        spanColor={`#FF986C`}
        className={'text-center mb-5 lg:mb-10 lg:text-[64px]'}
      />
      <Paragraph
        paragraphText={`Be your authentic self with flockx and connect to your local communities through short videos, helping you find real world experiences in Austin.`}
        className='text-center max-w-3xl mx-auto'
      />
      <Spacer sizeY={1} />
      <Spacer className='block md:hidden' />
      <div
        className='container mx-auto flex md:flex-row flex-col max-w-7xl gap-10 md:gap-5 lg:gap-10 items-center justify-center'>
        {communityShowcaseContent.map((content, index) => (
          <CommunityShowcase content={content} index={index} />
        ))}
      </div>
      <Spacer sizeY={2} />
      <Spacer className='block md:hidden' />
      <div>
        <Headline
          spanText={'Download the App'}
          spanColor='#6A67FF'
          className='mb-4 text-center'
        />
        <Paragraph
          paragraphText={`Join the community, start finding friends, and living new experiences today.`}
          className='max-w-xl mx-auto mb-4 md:mb-8 text-grey-100 text-center'
        />
        <div className='mx-auto flex items-center justify-center w-[min-content]'>
          <DownloadAppCTA />
        </div>
      </div>
      <Spacer sizeY={2} />
      <Spacer className='block md:hidden' />
    </BaseLayout>
  );
}

function AustinCommunityPageHeroContent() {
  return (
    <div className='-mt-[15%] sm:mt-0'>
      <div className='text-center sm:text-left sm:mt-6 lg:mt-0'>
        <PrimaryHeader
          headerText='Dive into the Ultimate Austin Experience'
          className='!text-4xl lg:!text-6xl xl:!text-[72px] mb-8 sm:mb-4 md:mb-8 lg:mb-8 text-white capitalize max-w-[390px] sm:max-w-sm md:max-w-md lg:max-w-3xl mx-auto lg:mr-auto sm:ml-0 lg:ml-0 lg:mt-10'
        />
        <DownloadAppCTA containerStyles='justify-center sm:justify-start' />
        <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
          Available in the US
        </p>
      </div>
    </div>
  );
}

export const query = graphql`
    query austinCommunityPageQuery {
        austinCommunityHeroImg: file(
            name: { eq: "beer-community-desktop-hero" }
        ) {
            childImageSharp {
                gatsbyImageData
            }
        }
        austinCommunityMobileHeroImg: file(
            name: { eq: "beer-community-mobile-hero" }
        ) {
            childImageSharp {
                gatsbyImageData
            }
        }
        beerCommunitySquareImg: file(name: { eq: "beer-square-card" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        tacoCommunitySquareImg: file(name: { eq: "taco-square-card" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        phoneDemoImg: file(name: { eq: "fetch-app-demo-2" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, width: 445)
            }
        }
        liveMusicCommunitySquareImg: file(
            name: { eq: "live-music-square-card" }
        ) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
    }
`;
