import React from 'react';

export default function SkeletonQuest({ index }) {
  const SpacerElement = () => (
    <div className='w-full h-[150px] hidden md:flex justify-center items-center'></div>
  );
  return (
    <div className='flex justify-center items-center flex-col md:block w-full max-w-[350px]'>
      {index % 2 != 0 && <SpacerElement />}
      <div className='animate-pulse w-full'>
        <div className=' mb-8 md:mb-5'>
          <div className='p-1 rounded-3xl w-full h-[650px] md:h-[350px] lg:h-[640px] bg-slate-500'></div>
        </div>
        <div>
          <div className='p-1 rounded-3xl w-full h-[300px] bg-slate-500'></div>
        </div>
      </div>
      {index % 2 == 0 && <SpacerElement />}
    </div>
  );
}
